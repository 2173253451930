import {
  applyTheme,
  themeFromSourceColor,
} from '@material/material-color-utilities'
import './css/global.css'

const seedColor = 0xea5550
const theme = themeFromSourceColor(seedColor)

applyTheme(theme)
